<template>
    <div>

        <div class="u-resume user-content flex">
            <div class="u-resume-wrapper" style="width:100%;">
                <div class="smrz u-no-resume" v-if="!showsm">
                    <div style="margin-bottom:20px; font-weight:bold; font-size:18px;">实名认证</div>
                    <!-- <div style="margin-bottom:20px; font-size:14px; color:f60;"><router-link to="/user/settingInfo/userInfo">提示：认证前请先确认姓名已经修改，如果没有您可以点击这里修改</router-link></div> -->
                    <el-input v-model="realname" placeholder="请输入真实姓名！" style="margin-bottom:15px;"></el-input>
                    <el-input v-model="personid" placeholder="请输入身份证号码！"></el-input>
                    <div class="choose-upload" @click="putrz">实名认证</div>
                </div>
                <div class="smrz u-no-resume" v-if="showsm">
                    <div style="margin-bottom:20px; text-align:center; padding-top:30px; font-size:24px;">您已经实名认证过了！</div>
                    <!-- <div style="margin-bottom:20px; font-size:14px; color:f60;"><router-link to="/user/settingInfo/userInfo">提示：认证前请先确认姓名已经修改，如果没有您可以点击这里修改</router-link></div> -->
                    
                </div>

            </div>

            <show-user-resume :showResume="showResumeStatus" :resumeInfo="resumeInfo"
                              @closeUserResume="closeUserResume"></show-user-resume>

        </div>

    </div>
</template>
<script>
    import UploadPdf from '../component/UploadPdf'
    import ShowUserResume from '../component/ShowUserResume'
    import userRequest from '../../../api/user'

    export default {
        components: {
            UploadPdf,    /* 上传简历 */
            ShowUserResume,
           
        },
        created(){
            
            this.user=JSON.parse(localStorage.getItem("userInfo"))
            if(this.user.birthday==null || this.user.birthday=='1980-01-01'){
                this.showsm=false
            }else{
                this.showsm=true
            }


            console.log(this.user.age)
        },
        mounted() {
           

        },
        data() {
            return {
                showResumeStatus: false,
                resumeListAsMe: [],  // 简历列表
                resumeInfo: {},
                status: true,
                user:null,
                showsm:false,
                personid:"",
                realname:"",
                sm:null,
                // resumeListAsMe: []
            }
        },
        methods: {
            putrz(){
                if(this.personid==""){
                    this.$message.error("请输入身份证号码！")
                    return
                }
                userRequest.setRealName({idNumber:this.personid,userName:this.realname}).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        userRequest.getUserInfo({}).then(ress=>{
                            console.log(ress)
                            localStorage.setItem("userInfo",JSON.stringify(ress.data))
                            this.$message.success("认证成功！")
                            this.user=ress.data
                            this.showsm=false
                            this.$router.push("/user/resumes")
                        })
                        
                    }else{
                        this.$message.error(res.message)
                    }
                }).catch(err=>{
                    this.$message.error(err)
                })
            },
            openUserResume(id) {
                this.getResumeInfo(id)
            },
            /**
             * resumeId
             * */
            deleteResume(id) {
                this.$confirm('即将删除当前简历，是否继续？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // console.log("删除的简历的id",id)
                    userRequest.deleteResume({resumeId: id}).then(res => {
                        if (res.code === 200) {
                            let cIndex = this.resumeListAsMe.findIndex(res => {
                                if (res.id === id) {
                                    return true;
                                }
                            })
                            this.resumeListAsMe.splice(cIndex, 1)

                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            // console.log("简历列表",this.resumeListAsMe)
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
            },
            editResume(id) {
                this.$router.push({
                    path: '/user/creatResume',
                    query: {
                        resumeId: id
                    }
                })
            },
            /**
             * 将当前的简历设置为默认简历
             * */
            setDefaultResume(id) {
                userRequest.setDefaultResume({resumeId: id}).then(res => {
                    if (res.code === 200) {
                        // console.log("设置默认简历的返回值", res)
                        this.$message({
                            type: 'success',
                            message: '设置成功'
                        });
                    }
                })
            },
            closeUserResume() {
                this.showResumeStatus = false
            },
            getUserResumeList() {
                userRequest.getUserResumeList().then((res) => {
                    if (res.code === 200) {
                        this.resumeListAsMe = res.data.resumeDOList
                        if (this.resumeListAsMe.length === 0) {
                            this.status = false
                        }
                    }
                }).catch((err) => {
                    this.status = false
                })
            },
            routeToCreatResume() {
                if(this.user.birthday==null || this.user.birthday=='1980-01-01'){
                    this.showsm=true
                    return
                }
                if(this.resumeListAsMe.length>5){
                    this.$message.info("至多可创建5份简历")
                    return;
                }
                this.$router.push({
                    path: '/user/creatResume'
                })
            },
            getResumeInfo(id) {
                userRequest.getResumeInfo({resumeId: id}).then((res) => {
                    if (res.code === 200) {
                        this.resumeInfo = res.data
                        setTimeout(() => {
                            this.showResumeStatus = true
                        }, 300)
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>

    .el-button {
        margin-left: 0px;
        margin-bottom: 10px;
    }

    @import "../../../assets/css/global.scss";
    .u-resume {
        min-height: 600px;
        margin-bottom: 100px;
        margin-top: 40px;
    }

    .u-resume-wrapper {
        position: relative;
        box-sizing: border-box;
        margin-top: 20px;
        min-height: 500px;
        background: #fff;
        width: 884px;
        padding: 20px;

        .u-resume-list {
            // box-sizing: border-box;
            // margin-top: 20px;
            // min-height: 500px;
            // background: #fff;
            // width: 884px;
            // padding: 20px;
            .default {
                color: $green;
                cursor: pointer;
                user-select: none;

                &:hover {
                    font-weight: 700;
                }
            }

            .delresume {
                margin-left: 8px;
                color: $danger;
                cursor: pointer;
                user-select: none;

                &:hover {
                    font-weight: 700;
                }
            }

            .add-resume {
                justify-content: flex-end;
                margin-top: 10px;
                cursor: pointer;

                .a-resume-btn {
                    display: inline-block;
                    width: 100px;
                    height: 40px;
                    line-height: 40px;
                    margin-right: 25px;
                    border-radius: 3px;
                    text-align: center;
                    background: #f6f6f6;
                    border: 1px solid #eee;

                    &:hover {
                        background: #eee;
                    }
                }
            }
        }

        .u-no-resume {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .no-resume-img {

            }

            .no-resume-text {
                margin-top: 10px;
                color: #999;
            }

            .choose-upload {
                cursor: pointer;
                margin-top: 30px;
                width: 300px;
                height: 46px;
                line-height: 46px;
                background: $main;
                text-align: center;
                color: #fff;
                border-radius: 6px;

                &:hover {
                    background: $hover;
                }
            }
        }
    }

    .sider-resume {
        margin-top: 20px;
        box-sizing: border-box;
        width: 300px;
        padding: 20px;
        background: #fff;
        margin-left: 14px;
        // .u-resume-title{
        //         font-size: 18px;
        //         line-height: 18px;
        //         color: #333;
        //         font-weight: 500;
        //         margin-bottom: 20px;
        // }
        // .upload-resume-warpper{
        //     .u-has-resume{
        //         padding: 10px;
        //         align-items: center;
        //         margin-bottom: 27px;
        //         .doc-pdf{
        //             width: 35px;
        //             height: 35px;
        //             padding: 0 13px;
        //             img{
        //                 width: 35px;
        //                 height: 35px;
        //             }
        //         }
        //         .doc-pdf-name{
        //             line-height: 18px;
        //             color: #333;
        //         }
        //     }
        //     .u-empty-resume{
        //         margin-bottom: 27px;
        //         font-size: 14px;
        //         color: #666;
        //         letter-spacing: 0;
        //         line-height: 26px;
        //     }
        // }
        // .u-upload__button{
        //     cursor: pointer;
        //     width: 100%;
        //     height: 36px;
        //     line-height: 36px;
        //     text-align: center;
        //     background: $main;
        //     border-radius: 20px;
        //     font-size: 14px;
        //     color: #fff;
        //     &:hover{
        //         background: $hover;
        //     }
        // }
        // .other-lable{
        //     margin-top: 30px;
        //     justify-content: center;
        //     .o-lable-item{
        //         cursor: pointer;
        //         flex: 1;
        //         flex-direction: column;
        //         justify-content: center;
        //         align-items: center;
        //         &:hover{
        //             color: $main;
        //         }
        //         .o-lable-img{
        //             width: 36px;
        //             height: 36px; 
        //             margin-bottom: 10px;
        //             img{
        //                 width: 36px;
        //                 height: 36px; 
        //             }
        //         }
        //     }
        // }
    }
</style>